import React from 'react';

import type { AttributesOnlyOrganization } from 'models';

import can from 'helpers/can';
import { useAppDispatch } from 'helpers/hooks';
import useFeatureFlags from 'helpers/hooks/useFeatureFlags';
import { __ } from 'helpers/i18n';
import {
  pathToAdminRights,
  pathToEntities,
  pathToIntegrations,
  pathToNotificationsSettings,
  pathToObjectivesSettings,
  pathToReviewsSettings,
  pathToThreeSixtySettings,
  pathToTrainingSettings,
} from 'helpers/paths';

import { withGenericErrorHandling } from 'lib/api/errorHandler';
import { genericFetch } from 'lib/api/genericFetch';

import { Button, Icon, Text } from 'components';

import NavItem from './NavItem';
import NavSection from './NavSection';

type Props = {
  organization: AttributesOnlyOrganization;
  hideSettingsPage: () => void;
};

const NavSettings = ({ organization, hideSettingsPage }: Props) => {
  const dispatch = useAppDispatch();
  const featureFlags = useFeatureFlags();

  return (
    <>
      <div className="flex items-center mb-4 px-2">
        <Button
          className="border-0 bg-transparent text-nav-icon hover:text-nav-icon"
          onClick={hideSettingsPage}
          hasIconOnly
        >
          <Icon size="small" className="pr-3" name="arrow_back" />
          <Text className="font-semibold !text-nav-names-default" size={6}>
            {__('Back')}
          </Text>
        </Button>
      </div>
      <NavSection title={__('Workspace')} className="px-2">
        {can({
          perform: 'manage_admin_roles',
          on: organization,
        }) && (
          <NavItem
            iconName="admin_panel_settings"
            title={__('Administrators')}
            path={pathToAdminRights()}
          />
        )}
        {can({
          perform: 'manage_entities',
          on: organization,
        }) && (
          <NavItem
            iconName="workspaces"
            title={__('Entities')}
            path={pathToEntities()}
          />
        )}
        {can({
          perform: 'manage_integrations',
          on: organization,
        }) && (
          <NavItem
            iconName="integration_instructions"
            title={__('Integrations')}
            path={pathToIntegrations()}
          />
        )}
        {can({
          perform: 'manage_notifications_settings',
          on: organization,
        }) && (
          <NavItem
            iconName="mail"
            title={__('Notifications')}
            path={pathToNotificationsSettings()}
          />
        )}
        {featureFlags.billingPage &&
          can({
            perform: 'create_billing_portal_session',
            on: organization,
          }) && (
            <NavItem
              iconName="open_in_new"
              title={__('Billing')}
              onClick={async () => {
                withGenericErrorHandling(
                  genericFetch({
                    method: 'POST',
                    url: 'billing/portal',
                  }) as Promise<unknown> as Promise<{ url: string }>
                )(dispatch).then(response =>
                  window.open(response.url, '_blank')
                );
              }}
            />
          )}
      </NavSection>
      <NavSection title={__('Product')} className="px-2">
        <NavItem
          iconName="speed"
          title={__('Reviews')}
          path={pathToReviewsSettings()}
        />
        <NavItem
          iconName="360"
          title={__('360 Feedback')}
          path={pathToThreeSixtySettings()}
        />
        <NavItem
          iconName="track_changes"
          title={__('Objectives')}
          path={pathToObjectivesSettings()}
        />
        <NavItem
          iconName="school"
          title={__('Training')}
          path={pathToTrainingSettings()}
        />
      </NavSection>
    </>
  );
};

export default NavSettings;
